<template>
  <div class="bt-transaksi pa-5">
    <h6
      class="text-h5 font-weight-bold orange--text text--lighten-1 d-flex align-center"
    >
      <div
        class="rounded-pill orange lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"
      >
        <v-icon small color="white">mdi-account-multiple-check</v-icon>
      </div>
      Registrasi Calon Anggota
    </h6>
    <div class="mt-5 d-flex align-center">
      <div
        class="d-flex align-center orange lighten-1 white--text py-1 px-2 rounded"
      >
        <h5 class="ma-0 me-3">{{ form.step }}</h5>
        <h5 class="ma-0">{{ form.titleStep[form.step] }}</h5>
      </div>
      <div class="d-flex align-center">
        <h5
          v-for="n in 3"
          :key="`ms-${n}`"
          v-show="form.step <= Number(n)"
          class="orange lighten-1 white--text py-1 px-2 mx-2 rounded"
        >
          {{ Number(n) + 1 }}
        </h5>
      </div>
    </div>
    <v-row>
      <v-col class="mt-5">
        <v-btn class="orange lighten-1 white--text" @click="dummyData()"
          >Gunakan Dummy Data</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="mt-5" v-show="form.step == 1">
      <v-col cols="12" class="py-0 mb-5">
        <h4>Data Anggota</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Nama Lengkap</label>
        <v-text-field
          v-model="form.data.nama"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Nama Panggilan</label>
        <v-text-field
          v-model="form.data.panggilan"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rembug</label>
        <v-select
          color="black"
          v-model="form.data.cm_code"
          :items="opt.rembug"
          @change="getAnggota()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Urut Kelompok</label>
        <v-text-field
          v-model="form.data.kelompok"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">LWK/ Simpok</label>
        <v-text-field
          v-model="form.data.setoran_lwk"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Simpanan Wajib</label>
        <v-text-field
          v-model="form.data.setoran_mingguan"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tanggal Gabung</label>
        <v-text-field
          v-model="form.data.tgl_gabung"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          type="date"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Referensi</label>
        <v-select
          v-model="form.data.referensi"
          :items="opt.referensi"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 my-5">
        <h4>Data Pribadi</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Jenis Kelamin</label>
        <v-select
          v-model="form.data.pribadi_jenis_kelamin"
          :items="opt.jenis_kelamin"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Nama Ibu Kandung</label>
        <v-text-field
          v-model="form.data.pribadi_ibu_kandung"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tempat Lahir</label>
        <v-text-field
          v-model="form.data.pribadi_tmp_lahir"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tanggal Lahir/ Usia</label>
        <v-text-field
          v-model="form.data.pribadi_tgl_lahir"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          type="date"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Ktp</label>
        <v-text-field
          v-model="form.data.pribadi_no_ktp"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          type="number"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Telp/ HP</label>
        <v-text-field
          v-model="form.data.pribadi_no_hp"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          type="number"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pendidikan</label>
        <v-select
          v-model="form.data.pribadi_pendidikan"
          :items="opt.pendidikan"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pekerjaan</label>
        <v-select
          v-model="form.data.pribadi_pekerjaan"
          :items="opt.pekerjaan"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Keterangan Pekerjaan</label>
        <v-text-field
          v-model="form.data.pribadi_ket_pekerjaan"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Literasi</label>
        <v-checkbox
          v-model="form.data.pribadi_literasi_latin"
          hide-details
          flat
          solo
          dense
          label="Baca Tulis Latin"
          value="1"
        />
        <v-checkbox
          v-model="form.data.pribadi_literasi_arab"
          hide-details
          flat
          solo
          dense
          label="Baca Tulis Arab"
          value="1"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Jumlah Keluarga</label>
        <v-text-field
          v-model="form.data.pasangan_jmlkeluarga"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Jumlah Tanggungan</label>
        <v-text-field
          v-model="form.data.pasangan_jmltanggungan"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Status Pernikahan</label>
        <v-select
          v-model="form.data.status_perkawinan"
          :items="opt.status_perkawinan"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <!-- <v-col cols="12" class="py-0 mb-3" description="Tap untuk mengubah gambar">
            <label for="">Upload Ktp</label>
            <label for="fm-ktp" class="w-100" ref="previewImage">
                <img :src="(form.data.doc_ktp) ? form.data.doc_ktp : require('@/assets/images/id-front.png')" fluid class="w-100"/>
                <input type="file" ref="fm-ktp" hidden id="fm-ktp"
                @change="previewImage($event,'ktp')" accept="image/*"> 
            </label>
        </v-col>
        <v-col cols="12" class="py-0 mb-3" description="Tap untuk mengubah gambar">
            <label for="">Upload Kartu Keluarga</label>
            <label for="fm-kk" class="w-100" ref="previewImage">
                <img :src="(form.data.doc_kk) ? form.data.doc_kk : require('@/assets/images/id-back.png')" fluid class="w-100"/>
                <input type="file" ref="fm-kk" hidden id="fm-kk"
                @change="previewImage($event,'kk')" accept="image/*"> 
            </label>
        </v-col>
        <v-col cols="12" class="py-0 mb-3" description="Tap untuk mengubah gambar">
            <label for="">Upload Dokumen Pendukung</label>
            <label for="fm-doc" class="w-100" ref="previewImage">
                <img :src="(form.data.doc_pendukung) ? form.data.doc_pendukung : require('@/assets/images/id-back.png')" fluid class="w-100"/>
                <input type="file" ref="fm-doc" hidden id="fm-doc"
                @change="previewImage($event,'doc')" accept="image/*"> 
            </label>
        </v-col> -->
    </v-row>
    <v-row class="mt-5" v-show="form.step == 2">
      <v-col cols="12" class="py-0 mb-5">
        <h4>Data Pasangan</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Nama</label>
        <v-text-field
          v-model="form.data.pasangan_nama"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tempat Lahir</label>
        <v-text-field
          v-model="form.data.pasangan_tmplahir"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tanggal Lahir/ Usia</label>
        <v-text-field
          v-model="form.data.pasangan_tglahir"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Ktp</label>
        <v-text-field
          v-model="form.data.pasangan_no_ktp"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Telp/ HP</label>
        <v-text-field
          v-model="form.data.pasangan_no_hp"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pendidikan</label>
        <v-select
          v-model="form.data.pasangan_pendidikan"
          :items="opt.pendidikan"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pekerjaan</label>
        <v-select
          v-model="form.data.pasangan_pekerjaan"
          :items="opt.pekerjaan"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Keterangan Pekerjaan</label>
        <v-text-field
          v-model="form.data.pasangan_ketpekerjaan"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Literasi</label>
        <v-checkbox
          v-model="form.data.pasangan_literasi_latin"
          hide-details
          flat
          solo
          dense
          label="Baca Tulis Latin"
          value="1"
        />
        <v-checkbox
          v-model="form.data.pasangan_literasi_arab"
          hide-details
          flat
          solo
          dense
          label="Baca Tulis Arab"
          value="1"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5" v-show="form.step == 3">
      <v-col cols="12" class="py-0 mb-5">
        <h4>Alamat Sesuai KTP</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Alamat</label>
        <v-text-field
          v-model="form.data.pribadi_alamat"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">RT/ RW</label>
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.data.rt"
              color="black"
              autocomplete="false"
              hide-details
              flat
              solo
              dense
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="form.data.rw"
              color="black"
              autocomplete="false"
              hide-details
              flat
              solo
              dense
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Desa</label>
        <v-text-field
          v-model="form.data.pribadi_desa"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Kecamatan</label>
        <v-text-field
          v-model="form.data.pribadi_kecamatan"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Kabupaten/ Kota</label>
        <v-text-field
          v-model="form.data.pribadi_kabupaten"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Kode Pos</label>
        <v-text-field
          v-model="form.data.pribadi_kodepos"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-5 mt-5">
        <h4>Alamat Tempat Tinggal</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <v-checkbox label="Sama dengan KTP" @change="setAlamat()" v-model="form.flagAlamat" hide-details
          flat
          solo
          dense/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Alamat</label>
        <v-text-field
          v-model="form.data.pribadi_koresponden_alamat"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">RT/ RW</label>
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.data.koresponden_rt"
              color="black"
              autocomplete="false"
              hide-details
              flat
              solo
              dense
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="form.data.koresponden_rw"
              color="black"
              autocomplete="false"
              hide-details
              flat
              solo
              dense
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Desa</label>
        <v-text-field
          v-model="form.data.pribadi_koresponden_desa"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Kecamatan</label>
        <v-text-field
          v-model="form.data.pribadi_koresponden_kecamatan"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Kabupaten/ Kota</label>
        <v-text-field
          v-model="form.data.pribadi_koresponden_kabupaten"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Kode Pos</label>
        <v-text-field
          v-model="form.data.pribadi_koresponden_kodepos"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <!-- <v-col cols="12" class="py-0 mb-3">
            <label for="">Persediaan Awal</label>
            <vue-numeric currency="Rp " separator="." v-model="form.data.persediaan_awal" class="white w-100 pa-2"/>
        </v-col> -->
    </v-row>
    <v-row class="mt-5" v-show="form.step == 4">
      <v-col cols="12" class="py-0 mb-5">
        <h4 class="border-bottom">Asset Rumah Tangga</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <h4 class="border-bottom">Rumah</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Status</label>
        <v-select
          v-model="form.data.rmhstatus"
          :items="opt.status_rumah"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Toilet</label>
        <v-select
          v-model="form.data.rmhjamban"
          :items="opt.jamban"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Sumber Air</label>
        <v-select
          v-model="form.data.rmhair"
          :items="opt.air"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Bahan Bakar</label>
        <v-select
          v-model="form.data.rmhbahanbakar"
          :items="opt.bahan_bakar"
          color="black"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3 mt-2">
        <h4 class="border-bottom">Lahan</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Sawah</label>
        <v-text-field
          v-model="form.data.lahansawah"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Kebun</label>
        <v-text-field
          v-model="form.data.lahankebun"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3 mt-2">
        <h4 class="border-bottom">Ternak</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Unggas</label>
        <v-text-field
          v-model="form.data.ternakunggas"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Kambing</label>
        <v-text-field
          v-model="form.data.ternakdomba"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Sapi/ Kerbau</label>
        <v-text-field
          v-model="form.data.ternakkerbau"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3 mt-2">
        <h4 class="border-bottom">Kendaraan</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Sepeda</label>
        <v-text-field
          v-model="form.data.kendsepeda"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Motor</label>
        <v-text-field
          v-model="form.data.kendmotor"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Mobil</label>
        <v-text-field
          v-model="form.data.kendmobil"
          color="black"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3 mt-2">
        <h4 class="border-bottom">Elektronik</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Elektronik yang dimiliki</label>
        <v-checkbox
          v-model="form.data.elekkulkas"
          hide-details
          flat
          solo
          dense
          label="Kulkas"
          value="1"
        />
        <v-checkbox
          v-model="form.data.elekmesincuci"
          hide-details
          flat
          solo
          dense
          label="Mesin Cuci"
          value="1"
        />
        <v-checkbox
          v-model="form.data.elektv"
          hide-details
          flat
          solo
          dense
          label="TV"
          value="1"
        />
        <v-checkbox
          v-model="form.data.elekkomputer"
          hide-details
          flat
          solo
          dense
          label="Komputer/ Laptop"
          value="1"
        />
        <v-checkbox
          v-model="form.data.elekhape"
          hide-details
          flat
          solo
          dense
          label="Handphone"
          value="1"
        />
        <v-checkbox
          v-model="form.data.elekvcd"
          hide-details
          flat
          solo
          dense
          label="VCD/ DVD"
          value="1"
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-center mt-4 pt-4">
      <v-btn
        class="orange lighten-1 white--text mx-2"
        @click="move(form.step - 1)"
        v-show="form.step > 1"
        >Kembali</v-btn
      >
      <v-btn
        class="orange lighten-1 white--text mx-2"
        @click="move(form.step + 1)"
        :disabled="form.loading"
      >
        {{ form.step == 4 ? "Daftar" : "Selanjutnya" }}
      </v-btn>
    </div>
    <v-snackbar v-model="alert.show" :timeout="3000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper"
export default {
  name: "KeangotaanMasuk",
  components: {
  },
  data: () => ({
    form: {
      data: {
        cm_code: null,
        nama: null,
        panggilan: null,
        kelompok: null,
        setoran_lwk: null,
        setoran_mingguan: null,
        tgl_gabung: null,
        referensi: 2,
        pribadi_jenis_kelamin: null,
        pribadi_ibu_kandung: null,
        pribadi_tmp_lahir: null,
        pribadi_tgl_lahir: null,
        pribadi_usia: null,
        pribadi_no_ktp: null,
        pribadi_no_hp: null,
        pribadi_pendidikan: 2,
        pribadi_pekerjaan: 0,
        pribadi_ket_pekerjaan: null,
        pribadi_literasi_latin: 1,
        pribadi_literasi_arab: 0,
        pasangan_jmlkeluarga: null,
        pasangan_jmltanggungan: null,
        status_perkawinan: 0,
        pasangan_nama: null,
        pasangan_tmplahir: null,
        pasangan_tglahir: null,
        pasangan_usia: null,
        pasangan_no_ktp: null,
        pasangan_no_hp: null,
        pasangan_pendidikan: 2,
        pasangan_pekerjaan: 6,
        pasangan_ketpekerjaan: null,
        pasangan_literasi_latin: 1,
        pasangan_literasi_arab: 0,
        pribadi_alamat: null,
        rt: null,
        rw: null,
        pribadi_desa: null,
        pribadi_kecamatan: null,
        pribadi_kabupaten: null,
        pribadi_kodepos: null,
        pribadi_koresponden_alamat: null,
        koresponden_rt: null,
        koresponden_rw: null,
        pribadi_koresponden_desa: null,
        pribadi_koresponden_kecamatan: null,
        pribadi_koresponden_kabupaten: null,
        pribadi_koresponden_kodepos: null,
        rmhstatus: 1,
        rmhjamban: 1,
        rmhair: 2,
        rmhbahanbakar: 2,
        lahansawah: null,
        lahankebun: null,
        ternakunggas: null,
        ternakdomba: null,
        ternakkerbau: null,
        kendsepeda: null,
        kendmotor: null,
        kendmobil: null,
        elekkulkas: null,
        elekmesincuci: null,
        elektv: null,
        elekkomputer: null,
        elekhape: null,
        elekvcd: null,
      },
      titleStep: ["", "Step 1", "Step 2", "Step 3", "Step 4"],
      step: 1,
      maxstep: 4,
      loading: false,
      flagAlamat: false
    },
    opt: {
      referensi: [
        {
          value: 1,
          text: "Saudara",
        },
        {
          value: 2,
          text: "Anggota lain",
        },
        {
          value: 3,
          text: "Tokoh Masyarakat",
        },
        {
          value: 4,
          text: "Petugas KSPPS BAIK",
        },
      ],
      jenis_kelamin: [
        { value: "W", text: "Wanita" },
        { value: "P", text: "Pria" },
      ],
      pendidikan: [
        { value: 0, text: "Tidak Sekolah" },
        { value: 1, text: "SD" },
        { value: 2, text: "SLTP" },
        { value: 3, text: "SLTA" },
        { value: 4, text: "Diploma" },
        { value: 5, text: "S1" },
        { value: 6, text: "S2 dan diatasnya" },
      ],
      pekerjaan: [
        { value: 0, text: "Ibu Rumah Tangga" },
        { value: 1, text: "Buruh" },
        { value: 2, text: "Petani" },
        { value: 3, text: "Pedagang" },
        { value: 4, text: "Wiraswasta" },
        { value: 5, text: "Pegawai Negeri Sipil" },
        { value: 6, text: "Karyawan Swasta" },
      ],
      status_perkawinan: [
        { value: 0, text: "Menikah" },
        { value: 1, text: "Belum Menikah" },
        { value: 2, text: "Duda / Janda" },
      ],
      status_rumah: [
        { value: 1, text: "Milik Sendiri" },
        { value: 2, text: "Sewa / Kontrak" },
        { value: 3, text: "Milik Keluarga" },
      ],
      jamban: [
        { value: 1, text: "Milik Sendiri" },
        { value: 2, text: "Sewa / Kontrak" },
        { value: 3, text: "Milik Keluarga" },
      ],
      air: [
        { value: 1, text: "Sumur Sendiri" },
        { value: 2, text: "PDAM / Sejenisnya" },
        { value: 3, text: "Mata air" },
        { value: 4, text: "Tadah hujan" },
        { value: 5, text: "Air isi ulang" },
        { value: 6, text: "Sungai" },
      ],
      bahan_bakar: [
        { value: 1, text: "Gas Alam" },
        { value: 2, text: "LPG" },
        { value: 3, text: "Minyak Tanah" },
        { value: 4, text: "Kayu Bakar" },
      ],
      rembug: []
    },
    alert: {
      show: false,
      msg: null,
    },
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    move(step) {
      var dateObj = new Date();
      dateObj.setDate(dateObj.getDate() + 3);
      var month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
      var day = String(dateObj.getUTCDate()).padStart(2, "0");
      var year = dateObj.getUTCFullYear();
      let lanjut = true;
      if (step > 0 && step <= this.form.maxstep) {
        if (Number(this.form.data.jangka_waktu) < 1) {
          this.notif("Warning", "Jangka waktu harus diisi", "warning");
          lanjut = false;
        } else {
          lanjut = true;
        }
        if (lanjut) {
          this.form.step = step;
        }
        this.scrollToTop();
      }
      if (step > 4) {
        this.doRegisterAnggota();
      }
    },
    async getRembug() {
      let day = new Date().getDay();
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("day", day);
      // payload.append("day", 1);
      try {
        let req = await services.infoRembug(payload, this.user.token);
        let { data, status } = req.data;
        if (status) {
          this.opt.rembug = [];
          data.map((item) => {
            let opt = {
              value: item.cm_code,
              text: item.cm_name,
              data: item,
            };
            this.opt.rembug.push(opt);
          });
        } else {
          this.alert = {
            show: true,
            msg: 'Data Rembug tidak ditemukan',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getAnggota() {
      let payload = new FormData();
      payload.append("cm_code", this.form.data.cm_code);
      try {
        let req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.form.data.kelompok = data.length + 1
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Anggota',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async doRegisterAnggota() {
      let payload = new FormData();
      let payloadData = this.form.data;
      payloadData.tgl_gabung = this.formatDate(payloadData.tgl_gabung)
      payloadData.pasangan_tglahir = this.formatDate(payloadData.pasangan_tglahir)
      payloadData.pribadi_tgl_lahir = this.formatDate(payloadData.pribadi_tgl_lahir)
      for (let key in payloadData) {
        payload.append(key, payloadData[key]);
      }
      this.form.loading = true;
      try {
        let req = await services.registrasiCalonAnggota(
          payload,
          this.user.token
        );
        if (req.status === 200) {
          this.alert = {
            show: true,
            msg: "Registrasi calon anggota berhasil",
          };
          setTimeout(() => {
            this.$router.push(`/keanggotaan`);
          }, 1000);
        } else {
          this.alert = {
            show: true,
            msg: "Registrasi calon anggota gagal",
          };
        }
        this.form.loading = false;
      } catch (error) {
        this.form.loading = false;
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    setAlamat() {
      if(this.form.flagAlamat){
        this.form.data.pribadi_koresponden_alamat = this.form.data.pribadi_alamat
        this.form.data.koresponden_rt = this.form.data.rt
        this.form.data.koresponden_rw = this.form.data.rw
        this.form.data.pribadi_koresponden_desa = this.form.data.pribadi_desa
        this.form.data.pribadi_koresponden_kecamatan = this.form.data.pribadi_kecamatan
        this.form.data.pribadi_koresponden_kabupaten = this.form.data.pribadi_kabupaten
        this.form.data.pribadi_koresponden_kodepos = this.form.data.pribadi_kodepos
      } else {
        this.form.data.pribadi_koresponden_alamat = null
        this.form.data.koresponden_rt = null
        this.form.data.koresponden_rw = null
        this.form.data.pribadi_koresponden_desa = null
        this.form.data.pribadi_koresponden_kecamatan = null
        this.form.data.pribadi_koresponden_kabupaten = null
        this.form.data.pribadi_koresponden_kodepos = null
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    dummyData() {
      let dummy = {
        cm_code: this.form.data.cm_code,
        kelompok: this.form.data.kelompok,
        nama: "MAEMUNAH",
        panggilan: "EMUN",
        setoran_lwk: "20000",
        setoran_mingguan: "0",
        tgl_gabung: "2022-09-07",
        referensi: 2,
        pribadi_jenis_kelamin: "W",
        pribadi_ibu_kandung: "MUNAH",
        pribadi_tmp_lahir: "BOGOR",
        pribadi_tgl_lahir: "1967-05-16",
        pribadi_usia: "55",
        pribadi_no_ktp: "3201605196770001",
        pribadi_no_hp: "081234567890",
        pribadi_pendidikan: 2,
        pribadi_pekerjaan: 0,
        pribadi_ket_pekerjaan: "Pedagang",
        pribadi_literasi_latin: 1,
        pribadi_literasi_arab: 0,
        pasangan_jmlkeluarga: "3",
        pasangan_jmltanggungan: "2",
        status_perkawinan: 0,
        pasangan_nama: "SUPARDI",
        pasangan_tmplahir: "BOGOR",
        pasangan_tglahir: "1966-06-17",
        pasangan_usia: "55",
        pasangan_no_ktp: "3201150312670002",
        pasangan_no_hp: "087870087956",
        pasangan_pendidikan: 2,
        pasangan_pekerjaan: 6,
        pasangan_ketpekerjaan: "Staf IT",
        pasangan_literasi_latin: 1,
        pasangan_literasi_arab: 0,
        pribadi_alamat: "KP KEBON KOPI",
        rt: "001",
        rw: "002",
        pribadi_desa: "DRAMAGA",
        pribadi_kecamatan: "CIAMPEA",
        pribadi_kabupaten: "BOGOR",
        pribadi_kodepos: "16116",
        pribadi_koresponden_alamat: "KP KEBON KOPI",
        koresponden_rt: "001",
        koresponden_rw: "002",
        pribadi_koresponden_desa: "DRAMAGA",
        pribadi_koresponden_kecamatan: "CIAMPEA",
        pribadi_koresponden_kabupaten: "BOGOR",
        pribadi_koresponden_kodepos: "16116",
        rmhstatus: 1,
        rmhjamban: 1,
        rmhair: 2,
        rmhbahanbakar: 2,
        lahansawah: "0",
        lahankebun: "0",
        ternakunggas: "0",
        ternakdomba: "0",
        ternakkerbau: "0",
        kendsepeda: "0",
        kendmotor: "2",
        kendmobil: "0",
        elekkulkas: "1",
        elekmesincuci: "1",
        elektv: "1",
        elekkomputer: "0",
        elekhape: "1",
        elekvcd: "0",
      };
      this.form.data = {...dummy}
    },
    notif(title, msg, type) {
      this.alert = {
        show: true,
        msg: msg,
      };
    },
  },
  mounted() {
    this.getRembug()
  }
};
</script>
